<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <ion-img
            [class]="getLogoClass()"
            [src]="getLogo()"
            (click)="forceASentryError()"
          ></ion-img>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list id="inbox-list" lines="none">
          <ion-note
            *ngIf="authService.userData && authService.userData.firstName"
            (click)="forceAnError()"
          >
            <span style="margin-right: 10px">
              {{
                authService.userData.firstName +
                  ' ' +
                  authService.userData.lastName
              }}
            </span>

            <ng-container
              *ngIf="
                profileService.premiumPartnerLevel !== null &&
                profileService.premiumPartnerLevel !== undefined
              "
            >
              <ion-icon
                *ngFor="let star of stars"
                [name]="star"
                [color]="getStarsColor()"
              ></ion-icon>
            </ng-container>
          </ion-note>

          <!-- admin pages start -->

          <!-- <ng-template [ngIf]="adminMenuActive">
            <ion-menu-toggle
              slot="content"
              auto-hide="false"
              *ngFor="let p of userAdminPages"
            >
              <ion-item
                routerDirection="root"
                [routerLink]="[p.url]"
                lines="none"
                detail="false"
                routerLinkActive="selected"
                (click)="p.title === 'Back to Movacar' && p.action()"
              >
                <ion-icon
                  style="color: transparent"
                  aria-hidden="true"
                  slot="start"
                  [ios]="p.icon"
                  [md]="p.icon"
                ></ion-icon>
                <ion-label>{{ p.title | translate | titlecase }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ng-template> -->
          <ng-template [ngIf]="this.authService.isLoggedIn() | async">
            <ion-accordion-group
              *ngIf="userAdminInspectionPages.length && adminMenuActive"
            >
              <ion-accordion>
                <ion-item slot="header" lines="none" detail="false">
                  <ion-icon
                    aria-hidden="true"
                    slot="start"
                    ios="car-sport-outline"
                    md="car-sport-sharp"
                  ></ion-icon>
                  <ion-label class="legal-label">{{
                    'Vehicle Inspection'
                  }}</ion-label>
                </ion-item>

                <ion-menu-toggle
                  *ngFor="let p of userAdminInspectionPages"
                  slot="content"
                  auto-hide="false"
                >
                  <ion-item
                    routerDirection="root"
                    [routerLink]="[p.url]"
                    lines="none"
                    detail="false"
                    routerLinkActive="selected"
                  >
                    <ion-icon
                      style="color: transparent"
                      aria-hidden="true"
                      slot="start"
                      [ios]="p.icon"
                      [md]="p.icon"
                    ></ion-icon>
                    <ion-label>{{ p.title | translate | titlecase }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-accordion>
            </ion-accordion-group>
          </ng-template>

          <ng-template
            [ngIf]="(this.authService.isLoggedIn() | async) && adminMenuActive"
          >
            <ion-item
              *ngFor="let userAdminPage of userAdminPages"
              [routerLink]="[userAdminPage.url]"
            >
              <ion-icon
                aria-hidden="true"
                slot="start"
                [ios]="userAdminPage.icon"
                [md]="userAdminPage.icon"
              ></ion-icon>
              <ion-label>{{ userAdminPage.title }}</ion-label>
            </ion-item>
            <ion-menu-toggle auto-hide="false">
              <ion-item
                button=""
                lines="none"
                detail="false"
                (click)="closeAdminMode()"
              >
                <ion-icon
                  aria-hidden="true"
                  slot="start"
                  ios="arrow-back"
                  md="arrow-back"
                ></ion-icon>
                <ion-label> {{ 'Back to Movacar' }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ng-template>
          <!-- admin pages end -->

          <app-fleet-menu
            *ngIf="
              (authService.isLoggedIn() | async) &&
              authService?.role === UserRole.FleetManager
            "
          />

          <ng-template [ngIf]="this.authService.isLoggedIn() | async">
            <ng-template ngIf="!adminMenuActive">
              <ion-menu-toggle auto-hide="false" *ngFor="let p of userPages">
                <ng-template
                  [ngIf]="
                    (p.url === '/transfers/' ||
                      profileService.premiumPartnerLevel > 0) &&
                    !adminMenuActive
                  "
                >
                  <ion-item
                    *ngIf="
                      !(
                        p.title === 'movacarpro_menu_drivers' &&
                        authService?.role === UserRole.SingleDriver
                      ) &&
                      !(
                        p.title === 'movacarpro_menu_transfers' &&
                        !this.companyService.company?.isActive
                      ) &&
                      !adminMenuActive
                    "
                    routerDirection="root"
                    [routerLink]="[p.url]"
                    lines="none"
                    detail="false"
                    routerLinkActive="selected"
                    (click)="p.title === 'Admin mode' && openAdminMenu()"
                  >
                    <ion-icon
                      aria-hidden="true"
                      slot="start"
                      [ios]="p.icon"
                      [md]="p.icon"
                    ></ion-icon>
                    <ion-label>{{ p.title | translate | titlecase }}</ion-label>
                  </ion-item>
                </ng-template>
              </ion-menu-toggle>
            </ng-template>

            <ion-accordion-group
              *ngIf="userTrainingPages.length && !adminMenuActive"
            >
              <ion-accordion>
                <ion-item slot="header" lines="none" detail="false">
                  <ion-icon
                    aria-hidden="true"
                    slot="start"
                    ios="barbell-outline"
                    md="barbell-sharp"
                  ></ion-icon>
                  <ion-label class="legal-label">{{
                    'movacarpro_menu_trainings' | translate
                  }}</ion-label>
                </ion-item>

                <ion-menu-toggle
                  *ngFor="let p of userTrainingPages"
                  slot="content"
                  auto-hide="false"
                >
                  <ion-item
                    routerDirection="root"
                    [routerLink]="[p.url]"
                    lines="none"
                    detail="false"
                    routerLinkActive="selected"
                  >
                    <ion-icon
                      style="color: transparent"
                      aria-hidden="true"
                      slot="start"
                      [ios]="p.icon"
                      [md]="p.icon"
                    ></ion-icon>
                    <ion-label>{{ p.title | translate | titlecase }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-accordion>
            </ion-accordion-group>

            <ion-list-header lines="full"></ion-list-header>

            <ion-accordion-group *ngIf="!adminMenuActive">
              <ion-accordion>
                <ion-item slot="header" lines="none" detail="false">
                  <ion-icon
                    aria-hidden="true"
                    slot="start"
                    ios="person-outline"
                    md="person-sharp"
                  ></ion-icon>
                  <ion-label class="legal-label">{{
                    'movacarpro_profile_title' | translate
                  }}</ion-label>
                </ion-item>

                <ng-template [ngIf]="this.authService.isLoggedIn() | async">
                  <ion-menu-toggle
                    *ngFor="let p of userProfilePages"
                    slot="content"
                    auto-hide="false"
                  >
                    <ion-item
                      routerDirection="root"
                      [routerLink]="[p.url]"
                      lines="none"
                      detail="false"
                      routerLinkActive="selected"
                    >
                      <ion-icon
                        style="color: transparent"
                        aria-hidden="true"
                        slot="start"
                        [ios]="p.icon + '-outline'"
                        [md]="p.icon + '-sharp'"
                      ></ion-icon>
                      <ion-label>{{
                        p.title | translate | titlecase
                      }}</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                </ng-template>

                <ion-menu-toggle auto-hide="false" slot="content">
                  <ion-item lines="none" detail="false">
                    <ion-icon
                      style="color: transparent"
                      aria-hidden="true"
                      slot="start"
                      ios="globe-outline"
                      md="globe-sharp"
                    ></ion-icon>

                    <p class="change-language">
                      {{ 'change_language' | translate | titlecase }}
                    </p>
                    <ion-select
                      class="language-select"
                      (ionChange)="changeLanguage()"
                      [(ngModel)]="this.languagesService.activeLanguage"
                      [label]="'change_language' | translate | titlecase"
                    >
                      <ion-select-option
                        *ngFor="let lang of this.languagesService.languages"
                        [value]="lang"
                      >
                        {{ lang.value | translate }}
                      </ion-select-option>
                    </ion-select>
                    <ion-img
                      class="flag-icon"
                      [src]="
                        'assets/icon/' +
                        this.languagesService.activeLanguage.value +
                        '.png'
                      "
                      alt="flag icon"
                    ></ion-img>
                  </ion-item>
                </ion-menu-toggle>
              </ion-accordion>
            </ion-accordion-group>

            <ion-accordion-group #accordionGroup *ngIf="!adminMenuActive">
              <ion-accordion value="legal">
                <ion-item slot="header" lines="none" detail="false">
                  <ion-icon
                    aria-hidden="true"
                    slot="start"
                    ios="document-text-outline"
                    md="document-text-sharp"
                  ></ion-icon>
                  <ion-label class="legal-label">{{
                    'movacarpro_menu_legal' | translate
                  }}</ion-label>
                </ion-item>

                <ion-menu-toggle
                  auto-hide="false"
                  slot="content"
                  (click)="toggleAccordion()"
                >
                  <ion-item>
                    <ion-icon
                      style="color: transparent"
                      aria-hidden="true"
                      slot="start"
                      ios="document-text-outline"
                      md="document-text-sharp"
                    ></ion-icon>
                    <a [routerLink]="'/agb'">
                      {{ 'movacarpro_menu_terms_and_conditions' | translate }}
                    </a>
                  </ion-item>
                </ion-menu-toggle>

                <ion-menu-toggle
                  auto-hide="false"
                  slot="content"
                  (click)="toggleAccordion()"
                >
                  <ion-item>
                    <ion-icon
                      style="color: transparent"
                      aria-hidden="true"
                      slot="start"
                      ios="document-text-outline"
                      md="document-text-sharp"
                    ></ion-icon>
                    <a [routerLink]="'/datenschutz'">{{
                      'movacarpro_menu_privacy_policy' | translate
                    }}</a>
                  </ion-item>
                </ion-menu-toggle>

                <ion-menu-toggle
                  auto-hide="false"
                  slot="content"
                  (click)="toggleAccordion()"
                >
                  <ion-item>
                    <ion-icon
                      style="color: transparent"
                      aria-hidden="true"
                      slot="start"
                      ios="document-text-outline"
                      md="document-text-sharp"
                    ></ion-icon>
                    <a [routerLink]="'/impressum'">
                      {{ 'movacarpro_imprint' | translate }}
                    </a>
                  </ion-item>
                </ion-menu-toggle>
              </ion-accordion>
            </ion-accordion-group>
          </ng-template>

          <ng-template [ngIf]="this.authService.isLoggedIn() | async">
            <ion-menu-toggle auto-hide="false">
              <ion-item
                button=""
                lines="none"
                detail="false"
                (click)="logout()"
              >
                <ion-icon
                  aria-hidden="true"
                  slot="start"
                  ios="log-out-outline"
                  md="log-out-sharp"
                ></ion-icon>
                <ion-label style="font-weight: 400">{{
                  'Logout' | translate
                }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ng-template>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>

  <app-update-request-modal></app-update-request-modal>
  <app-forbidden-browser-modal></app-forbidden-browser-modal>
</ion-app>
