import { Component, NgZone, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { IonAccordionGroup } from '@ionic/angular';
import * as Sentry from '@sentry/angular-ivy';
import { lastValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthService, UserData, UserRole } from './auth/auth.service';
import { CompanyService } from './company/services/company.service';
import { ProfileService } from './profile/services/profile.service';
import { LanguagesService } from './services/languages.service';
import { OfflineService } from './services/offline.service';
import { ToastService } from './services/toast.service';
import { FirebaseAnalyticsService } from './shared/services/firebase-analytics.service';
import { TrainingsService } from './trainings/services/trainings.service';
import { VehicleInspectionService } from './vehicle-inspection/services/vehicle-inspection.service';
import { URLOpenListenerEvent } from '@capacitor/app';
import { App } from '@capacitor/app';
import { AdminInspectionService } from './admin/services/admin-inspection.service';

declare var ZohoSalesIQ: any;

interface MenuItem {
  title: string;
  url?: string;
  icon: string;
  roles: UserRole[];
  action?: () => void;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('accordionGroup', { static: true })
  accordionGroup: IonAccordionGroup;
  languageWasChanged = false;

  currentUserRole: UserRole | undefined;

  userPages: any[] = [];
  userProfilePages: any[] = [];
  userTrainingPages: any[] = [];
  userAdminInspectionPages: any[] = [];
  userAdminPages: any[] = [];

  syncInProgress = false;
  shouldForceErrorCount = 0;

  driverRoles = [
    UserRole.Driver,
    UserRole.SingleDriver,
    UserRole.ManagerDriver,
  ];

  managerRoles = [
    UserRole.Manager,
    UserRole.SingleDriver,
    UserRole.ManagerDriver,
  ];

  allRoles = [...this.driverRoles, ...this.managerRoles];

  pages: MenuItem[] = [];

  standardPages: MenuItem[] = [
    {
      title: 'movacarpro_menu_my_transfers',
      url: '/my-transfers/',
      icon: 'reader',
      roles: this.driverRoles,
    },
    {
      title: 'movacarpro_menu_tours',
      url: '/tours/',
      icon: 'file-tray-full',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_biddings',
      url: '/biddings/',
      icon: 'ticket',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_transfers',
      url: '/transfers/',
      icon: 'car',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_notifications',
      url: '/alerts/',
      icon: 'notifications',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_billings',
      url: '/billings/',
      icon: 'receipt',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_drivers',
      url: '/drivers/',
      icon: 'people',
      roles: this.managerRoles,
    },
    {
      title: 'movacarpro_menu_reports',
      url: '/reports/',
      icon: 'bar-chart',
      roles: this.managerRoles,
    },
  ];

  profilePages = [
    {
      title: 'movacarpro_menu_personal_info',
      url: '/profile/',
      icon: 'person-circle',
      roles: this.driverRoles,
    },
    {
      title: 'movacarpro_company_profile_title',
      url: '/company/',
      icon: 'business',
      roles: this.managerRoles,
    },
  ];

  trainingPages = [
    {
      title: 'movacarpro_menu_trainings_submenu',
      url: '/trainings/',
      icon: 'barbell',
      roles: this.allRoles,
    },
    {
      title: 'movacarpro_menu_reporting_quality_title',
      url: '/quality-scoring/',
      icon: 'barbell',
      roles: this.allRoles,
    },
  ];

  adminInspectionPages = [
    {
      title: 'Create New Inspection',
      url: 'admin/inspection/create',
      icon: '',
      roles: this.allRoles,
    },
    {
      title: 'Edit Inspection',
      url: 'admin/inspection/edit',
      icon: '',
      roles: this.allRoles,
    },
  ];

  adminPages = [
    {
      title: 'Poker',
      url: '/poker',
      icon: 'game-controller-outline',
      roles: this.allRoles,
    },
  ];

  UserRole = UserRole;

  private _adminMode = false;
  private _adminMenuActive = false;

  get adminMode(): boolean {
    return this._adminMode;
  }

  set adminMode(value: boolean) {
    this._adminMode = value;
    localStorage.setItem('adminMode', value.toString());
  }

  get adminMenuActive(): boolean {
    return this._adminMenuActive;
  }

  set adminMenuActive(value: boolean) {
    this._adminMenuActive = value;
    localStorage.setItem('adminMenuActive', value.toString());
  }

  constructor(
    public languagesService: LanguagesService,
    public authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private offlineService: OfflineService,
    public profileService: ProfileService,
    public companyService: CompanyService,
    private vehicleInspectionService: VehicleInspectionService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private trainingsService: TrainingsService,
    private zone: NgZone,
    private adminInspectionService: AdminInspectionService,
  ) {
    this.pages = [...this.standardPages];

    this.initializeApp();
    this.loadAdminState();
  }

  async ngOnInit(): Promise<void> {
    await this.startFirebase();

    await this.offlineService.getNetworkStatus();

    this.authService.authSubject$.subscribe((authenticated: boolean) => {
      if (!authenticated) {
        this.router.navigate(['/login']);

        return;
      }

      if (this.adminMode && this.adminMenuActive) {
        this.router.navigate(['/admin']);
      }

      this.authService.getUserData().subscribe({
        next: async (userData) => {
          if (!userData) {
            throw Error('movacarpro_error_load_user_data_failed_on_init');
          }

          this.authService.role = userData.role;

          this.currentUserRole = userData.role;

          this.adminMode = this.isAdminUser(userData);

          this.addAdminModeTab();

          this.userPages = this.pages.filter((p) =>
            p.roles?.includes(userData.role),
          );

          this.userProfilePages = this.profilePages.filter((p) =>
            p.roles?.includes(userData.role),
          );

          this.userTrainingPages = this.trainingPages.filter((p) =>
            p.roles?.includes(userData.role),
          );

          this.userAdminInspectionPages = this.adminInspectionPages.filter(
            (p) => p.roles?.includes(userData.role),
          );

          this.userAdminPages = this.adminPages.filter((p) =>
            p.roles?.includes(userData.role),
          );

          if (this.languageWasChanged) {
            return;
          }

          this.setLanguageToUserLanguage(userData);

          if (userData.role === UserRole.FleetManager) {
            return;
          }

          const profile = await lastValueFrom(
            this.profileService.fetchContactProfile(),
          );

          if (!profile) {
            return;
          }

          this.profileService.premiumPartnerLevel = profile.premiumPartnerLevel;

          await this.trainingsService.fetchTrainings(userData.role);

          const currentPremiumPartnerLevel =
            this.profileService.premiumPartnerLevel;

          const newPremiumPartnerLevel =
            await this.trainingsService.getPremiumPartnerLevelByTraining(
              this.trainingsService.trainings,
            );

          this.profileService.updatePremiumPartnerLevelInSalesforce(
            currentPremiumPartnerLevel,
            newPremiumPartnerLevel,
          );

          this.profileService.premiumPartnerLevel = newPremiumPartnerLevel;

          if (
            this.profileService.premiumPartnerLevel === 0 ||
            newPremiumPartnerLevel === 0
          ) {
            this.trainingsService.showOnboardingModal();
          }

          const company = await lastValueFrom(
            this.companyService.fetchCompanyProfile(),
          );

          if (!company) {
            return;
          }

          this.companyService.company = company;

          await this.vehicleInspectionService.loadInspections();
        },
        error: this.toastService.showErrorToast,
      });
    });

    await this.startZohoSalesIQ();
  }

  changeLanguage() {
    this.languageWasChanged = true;
    this.languagesService.languageChange();
  }

  toggleAccordion = () => {
    const isMobileView = window.innerWidth < 992;

    if (!isMobileView) {
      return;
    }

    const nativeEl = this.accordionGroup;
    const nativeElValue = nativeEl.value === 'legal' ? 'undefined' : 'legal';

    nativeEl.value = nativeElValue;
  };

  logout() {
    this.removeAdminTab();
    this.adminInspectionService.clearToken();
    this.adminMode = false;
    this.adminMenuActive = false;
    this.currentUserRole = undefined;

    return this.authService.logout().subscribe({
      next: async () => {
        await this.router.navigate(['login']);
      },
      error: this.toastService.showErrorToast,
    });
  }

  forceAnError() {
    if (this.shouldForceErrorCount < 5) {
      this.shouldForceErrorCount++;

      return;
    }

    this.shouldForceErrorCount = 0;

    throw new Error('MovacarPRO: This error is just a test!');
  }

  forceASentryError() {
    try {
      this.forceAnError();
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  private setLanguageToUserLanguage(userData: UserData) {
    this.languagesService.activeLanguage =
      this.languagesService.languages.find(
        (lan) => lan.key === userData.language.toLowerCase(),
      ) || this.languagesService.activeLanguage;

    this.languagesService.languageChange();
  }

  get stars() {
    const totalStars = 4;
    const premiumLevel = this.profileService.premiumPartnerLevel ?? 0;

    return Array.from({ length: totalStars }, (_, index) =>
      index < premiumLevel ? 'star' : 'star-outline',
    );
  }

  private async startFirebase() {
    await this.firebaseAnalyticsService.initializeFirebase();
    await this.firebaseAnalyticsService.attachToRouter();
  }

  private async startZohoSalesIQ() {
    const platform: string = Capacitor.getPlatform();

    if (platform === 'web') {
      return;
    }

    const { appKey, accessKey } = environment.zoho.salesIQ[platform];

    ZohoSalesIQ.init(appKey, accessKey, (_: any) => {
      //Use the ZohoSalesIQ.Launcher.show API if you wish to show the launcher by default.
      ZohoSalesIQ.Launcher.show(ZohoSalesIQ.Launcher.VisibilityMode.ALWAYS);
    });
  }

  isAdminUser(userData: UserData): boolean {
    const userEmail = userData.email;

    const isAdmin =
      userEmail.endsWith('movacar.com') || userEmail.endsWith('movacar.de');

    this.adminMode = isAdmin;

    return isAdmin;
  }

  private loadAdminState() {
    const savedAdminMode = localStorage.getItem('adminMode');
    const savedAdminMenu = localStorage.getItem('adminMenuActive');

    if (savedAdminMode) {
      this._adminMode = savedAdminMode === 'true';
    }

    if (savedAdminMenu) {
      this._adminMenuActive = savedAdminMenu === 'true';
    }
  }

  addAdminModeTab() {
    if (!this.adminMode) {
      this.userPages = this.getOriginalMenuItems();

      return;
    }

    this.pages = [
      {
        title: 'Admin mode',
        url: '/admin',
        icon: 'construct',
        roles: this.allRoles,
      },
      ...this.pages,
    ];
  }

  removeAdminTab() {
    return (this.pages = this.pages.filter(
      (page) => page.title !== 'Admin mode',
    ));
  }

  openAdminMenu() {
    this.adminMenuActive = true;
  }

  closeAdminMode() {
    this.adminMenuActive = false;

    this.router.navigate(['transfers']);
  }

  getOriginalMenuItems() {
    return (this.pages = [...this.standardPages]);
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('.com').pop();

        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  getLogo() {
    return this.currentUserRole &&
      this.currentUserRole === UserRole.FleetManager
      ? 'assets/logo-fleet.svg'
      : 'assets/logo.svg';
  }

  getLogoClass() {
    return this.currentUserRole &&
      this.currentUserRole === UserRole.FleetManager
      ? 'fleet-logo'
      : 'logo';
  }

  getStarsColor() {
    return this.currentUserRole &&
      this.currentUserRole === UserRole.FleetManager
      ? 'secondary'
      : 'primary';
  }
}
